figure {
  position: relative;
  //overflow: hidden;
  //width: 100%;
  //height: auto;

  margin: 0 0 2rem 0;
  //border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 0 1px #999;
  img {
    width: 100%;
    //max-width: none;
    height: auto;
    //border: 1px solid rgba(0,0,0,0.15);
    //box-shadow: 0 0 1px #999;
  }
}

figcaption {
  width: 100%;
  padding: 0.5rem;

  text-align: center;
  //font-size: ;

  color: $white;
  background-color: rgba(0,0,0,0.5);
}

[data-aspect-ratio] {
  position: relative;

  display: block;

  max-width: 100%;
  &:before {
    display: block;

    content: "";
  }
  > img {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;
  }
}
[data-aspect-ratio="3:1"]:before {
  padding-top: 33.33%;
}
[data-aspect-ratio="2:1"]:before {
  padding-top: 50%;
}
[data-aspect-ratio="16:9"]:before {
  padding-top: 56.25%;
}
[data-aspect-ratio="3:2"]:before {
  padding-top: 66.66%;
}
[data-aspect-ratio="4:3"]:before {
  padding-top: 75%;
}
[data-aspect-ratio="1:1"]:before {
  padding-top: 100%;
}
[data-aspect-ratio="3:4"]:before {
  padding-top: 133.33%;
}
[data-aspect-ratio="2:3"]:before {
  padding-top: 150%;
}
[data-aspect-ratio="9:16"]:before {
  padding-top: 177.77%;
}
[data-aspect-ratio="1:2"]:before {
  padding-top: 200%;
}
[data-aspect-ratio="1:3"]:before {
  padding-top: 300%;
}
