
@font-face {
  font-family: 'UniSans';
  font-weight: 100;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_thin-webfont';
}
@font-face {
  font-family: 'UniSans';
  font-weight: 200;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_light-webfont';
}
@font-face {
  font-family: 'UniSans';
  font-weight: 300;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_book-webfont';
}
@font-face {
  font-family: 'UniSans';
  font-weight: 400;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_regular-webfont';
}
@font-face {
  font-family: 'UniSans';
  font-weight: 600;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_semibold-webfont';
}
@font-face {
  font-family: 'UniSans';
  font-weight: 700;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_bold-webfont';
}
@font-face {
  font-family: 'UniSans';
  font-weight: 900;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_heavy-webfont';
}


@font-face {
  font-family: 'UniSans Thin';
  font-weight: normal;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_thin-webfont';
}
@font-face {
  font-family: 'UniSans Light';
  font-weight: normal;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_light-webfont';
}
@font-face {
  font-family: 'UniSans Book';
  font-weight: normal;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_book-webfont';
}
@font-face {
  font-family: 'UniSans Regular';
  font-weight: normal;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_regular-webfont';
}
@font-face {
  font-family: 'UniSans Semibold';
  font-weight: bold;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_semibold-webfont';
}
@font-face {
  font-family: 'UniSans Bold';
  font-weight: bold;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_bold-webfont';
}
@font-face {
  font-family: 'UniSans Heavy';
  font-weight: bold;
  font-style: normal;

  font-path: '../fonts/UniSans/uni_sans_heavy-webfont';
}

.fw-light {
  font-weight: 200;
}
