.main {
  //min-height: 1400px;
}
.main > hr,
.intro hr {
  max-width: none;
  margin-left: 0;
  margin-right: 0;
}

.intro h2 {
  font-weight: 200;
}

.preview__figure {
  figcaption {
    position: absolute;
    top: 50%;
    left: 25%;
    //height: 30%;
    width: 100%;
    //margin: -15% 0 0 -25%;
    margin: -10% 0 0 -25%;
  }
}

  // Heading sizes
  @each $size, $headers in $header-sizes {
    @include breakpoint($size) {
      @each $header, $font-size in $headers {
        .#{$header} {
          font-size: rem-calc($font-size);
        }
      }
    }
  }

.uebersicht {
  display: flex;
  align-items: center;
  background-color: $abdecker;

  .logo {
    flex: 1 0 0;
    // width, da es im IE nicht funktioniert und das Loggo in 100% angezeigt wird
    width: 20%;
  }

  .projekt {
    flex-basis: 80%;
    //color: $white;
    padding-left: 2rem;

  }
}

.main__content {
  //position: relative;
  min-height: 300px;
}

.wrapper {
  position: relative;
}

.nav {
  a {
    position: fixed;
    display: block;
    top: calc(50% - 140px);
    //left: 20px;
    font-size: 140px;
    font-weight: 500;
  }

  .back {
    left: 20px;
  }
  .next {
    right: 20px;
  }
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

body {
  margin-bottom: 3em;
}
.footer {
  background-color: $abdecker;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 3em;
  .columns {
    margin-top: .5em;
  }

  .menu > li > a {
    padding-left: 0;
  }
}