.m-t-0 {
  margin-top: 0;
}

.m-t-1 {
  margin-top: 1rem;
}

.m-t-15 {
  margin-top: 1.5rem;
}

.m-t-2 {
  margin-top: 2rem;
}

.m-t-3 {
  margin-top: 3rem;
}
